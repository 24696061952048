import React from "react";

import aboutImg from "../../assets/images/about/about-img1.png";
import aboutImg2 from "../../assets/images/about/about-img2.png";
import aboutImg3 from "../../assets/images/about/about-img3.png";
import aboutImg4 from "../../assets/images/about/about-img4.png";
import restaurantText from "../../assets/images/about/restaurant-text.png";

const AboutSection = () => {
  return (
    <div className="container">
      <div className="restro-history">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-6">
            <div
              className="our_history wow fadeIn"
              data-wow-duration="3s"
              data-wow-delay=".3s"
            >
              <span
                className="wow section-heading fadeInDown"
                data-wow-duration="2s"
                data-wow-delay=".3s"
              >
                Notre Histoire
              </span>
              <h2
                className="foholic_title wow fadeInDown"
                data-wow-duration="1.2s"
                data-wow-delay=".2s"
              >
                Bienvenue au Ware House!
              </h2>
              <p className="foholic_subcontent">
                Situé au cœur de Lubumbashi, le <b>Ware House</b> est un
                complexe exceptionnel qui allie un restaurant gastronomique à un
                lounge-bar élégant. Notre cuisine raffinée et nos cocktails
                innovants, élaborés avec des marques prestigieuses telles que
                Martell et Jameson, vous promettent une expérience culinaire
                inégalée.<br></br>
                <br></br>Depuis notre ouverture, nous créons des moments
                inoubliables dans une atmosphère chaleureuse et accueillante.
                Venez découvrir pourquoi <b>Ware House</b> est la destination de
                choix pour les amateurs de soirées d’exception.
              </p>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-6">
            <div className="resto-img text-end">
              <div className="theme-btn">
                <div className="round-text">
                  <img
                    src={restaurantText}
                    className="img-fluid restaurant-text-img"
                    alt="restaurant-text"
                  />
                  <div className="foholic_letter">W</div>
                </div>
              </div>
              <img src={aboutImg} alt="history img-1" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>

      <div className="food_tasting">
        <div className="row">
          <div className="col-12 col-md-5 col-lg-5">
            <div
              className="season_tast wow fadeInLeft"
              data-wow-duration="1s"
              data-wow-delay="1s"
            >
              <img src={aboutImg2} alt="history img-2" className="img-fluid" />
            </div>
          </div>
          <div className="col-12 col-md-7 col-lg-7">
            <div
              className="tasting_content  wow fadeInRight"
              data-wow-duration="1s"
              data-wow-delay="0.8s"
            >
              <h3 className="foholic_title">
                Notre menu dégustation propose une sélection saisonnière de
                produits de qualité
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div className="resto_foods">
        <h3
          className="foholic_title text-center col-lg-8 m-auto wow fadeIn"
          data-wow-duration="3s"
          data-wow-delay=".4s"
        >
          Célébrons la saison des saveurs toute l’année. Savourez une expérience
          gustative unique.
        </h3>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4">
            <div
              className="fo-abo-last wow fadeInLeft"
              data-wow-duration="1s"
              data-wow-delay="0.6s"
            >
              <p className="foholic_subcontent">
                Le <b>Ware House</b> se distingue par son engagement envers la
                durabilité et la responsabilité sociale. En partenariat avec des
                producteurs locaux, nous garantissons la fraîcheur et la qualité
                de chaque ingrédient. <br />
                <br />
                Nous organisons régulièrement des événements exclusifs, comme
                des Grooving Nights et des concerts live, pour enrichir votre
                expérience. Plongez dans un univers où chaque détail est
                soigneusement pensé pour vous offrir des moments inoubliables,
                dans une atmosphère à la fois conviviale et raffinée.
              </p>
              <div className="foholic_button">
                <a href="#menu" className="button-light">
                  Voir le menu
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-8">
            <div className="food-differs">
              <img
                src={aboutImg3}
                alt="history img-3"
                className="img-fluid float-end"
              />
              <img
                src={aboutImg4}
                alt="history img-4"
                className="img-fluid top-img"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
