import React, { useEffect } from "react";
import headerLogo from "../assets/images/logo/wh-logo.png";
import { Link, useNavigate } from "react-router-dom";

const Sidebar = () => {
  const navigate = useNavigate();

  useEffect(() => {
    function scrollToTarget(target) {
      const $target = document.querySelector(target);
      if ($target) {
        window.scrollTo({
          top: $target.offsetTop + 1,
          behavior: "smooth",
        });
      }
    }
    scrollToTarget("#home");
    function handleClick(e) {
      e.preventDefault();
      const target = e.currentTarget.getAttribute("value");
      scrollToTarget(target);
      window.history.pushState("", "", target);
    }

    const divsWithValueAttribute = document.querySelectorAll('div[value^="#"]');
    divsWithValueAttribute.forEach((div) => {
      div.addEventListener("click", handleClick);
    });

    return () => {
      divsWithValueAttribute.forEach((div) => {
        div.removeEventListener("click", handleClick);
      });
    };
  }, []);

  return (
    <header className="foholic-fixed-sidebar sidebar-right" id="header">
      <div className="header-container">
        <div className="menu-wrap">
          <div className="header-logo">
            <Link to="/">
              <img
                src={headerLogo}
                alt="Warehouse Header Logo"
                className="img-fluid"
              />
            </Link>
          </div>
          <nav className="fixed-menu-wrap" id="top-nav">
            <ul className="navbar-navs">
              <li className="menu-list">
                <div
                  className="nav-links"
                  value="#home"
                  onClick={() => navigate("/#home")}
                >
                  Home
                </div>
              </li>
              <li className="menu-list">
                <div
                  className="nav-links"
                  value="#about"
                  onClick={() => navigate("/#about")}
                >
                  About Us
                </div>
              </li>
              <li className="menu-list">
                <div
                  className="nav-links"
                  value="#team"
                  onClick={() => navigate("/#team")}
                >
                  Our Team
                </div>
              </li>
              <li className="menu-list">
                <div
                  className="nav-links"
                  value="#menu"
                  onClick={() => navigate("/#menu")}
                >
                  Our Menu
                </div>
              </li>
              <li className="menu-list">
                <div
                  className="nav-links"
                  value="#nightclub"
                  onClick={() => navigate("/#nightclub")}
                >
                  Z'e Night Club
                </div>
              </li>

              <li className="menu-list">
                <div
                  className="nav-links"
                  value="#reservation"
                  onClick={() => navigate("/#reservation")}
                >
                  Reservations
                </div>
              </li>
              <li className="menu-list">
                <div
                  className="nav-links"
                  value="#blog"
                  onClick={() => navigate("/#blog")}
                >
                  Articles
                </div>
              </li>
              <li className="menu-list">
                <div
                  className="nav-links"
                  value="#contact"
                  onClick={() => navigate("/#contact")}
                >
                  Contact
                </div>
              </li>
            </ul>
          </nav>
          <div className="menu-table">
            <span className="table-detail">Réservez Maintenant</span>
            <div className="booking-number">
              <a href="tel:(243)-858-876-575">+243 858 876 575</a>
              <br />

              <a href="tel:(243)-907-726-197">+243 907 726 197</a>
            </div>
          </div>
          <div className="foholic-social-ic">
            <a
              href="https://www.facebook.com/U.E.K.Officiel?mibextid=LQQJ4d"
              className="social-ic"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa-brands fa-facebook-f"></i>
            </a>
            <a
              href="https://www.tiktok.com/@warehousedrc?_t=8p0afHt7fGg&_r=1"
              className="social-ic"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa-brands fa-tiktok"></i>
            </a>
            <a
              href="https://www.instagram.com/warehousedrc?igsh=MTl0cTc1M3V1OXB0Mg%3D%3D&utm_source=qr"
              className="social-ic"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa-brands fa-instagram"></i>
            </a>
            <a
              href="https://www.youtube.com/@WareHouseDRC"
              className="social-ic"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa-brands fa-youtube"></i>
            </a>
            <a
              href="https://www.google.com/maps/place/Ware+House+Night+Club+Lubumbashi/@-11.6532521,27.4674559,15z/data=!4m20!1m13!4m12!1m4!2m2!1d27.4673842!2d-11.660213!4e1!1m6!1m2!1s0x19723f3fd8ec0d75:0x2f717ed401916b12!2sware+house+drc+lubumbashi!2m2!1d27.4777556!2d-11.6532521!3m5!1s0x19723f3fd8ec0d75:0x2f717ed401916b12!8m2!3d-11.6532521!4d27.4777556!16s%2Fg%2F11w7prz3fb?entry=ttu&g_ep=EgoyMDI0MDgyMy4wIKXMDSoASAFQAw%3D%3D"
              className="social-ic"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa-brands fa-google"></i>
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Sidebar;
