import React from "react";

import aboutImg from "../../assets/images/nightclub/guests06.jpg";
import aboutImg2 from "../../assets/images/nightclub/guests01.jpg";
import aboutImg3 from "../../assets/images/nightclub/night_club03.jpg";
import aboutImg4 from "../../assets/images/nightclub/dj_raph01.jpg";
import restaurantText from "../../assets/images/nightclub/restaurant-text.png";

const WarehouseAtNight = () => {
  return (
    <div className="container">
      <div className="restro-history">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-6">
            <div
              className="our_history wow fadeIn"
              data-wow-duration="3s"
              data-wow-delay=".3s"
            >
              <span
                className="wow section-heading fadeInDown"
                data-wow-duration="2s"
                data-wow-delay=".3s"
              >
                The Night Club
              </span>
              <h2
                className="foholic_title wow fadeInDown"
                data-wow-duration="1.2s"
                data-wow-delay=".2s"
              >
                The Ware House at Night
              </h2>
              <p className="foholic_subcontent">
                Le night-club du <b>Ware House</b>, ouvert Vendredi et Samedi à
                partir de 23H00’, vous plonge dans l'univers nocturne le plus
                vibrant de la ville. Parmi les plus grands night-clubs de la
                région, il offre un espace vaste et élégant où chaque soirée est
                une fête inoubliable. Profitez d'une ambiance électrisante et
                d'une décoration sophistiquée, conçue pour vous faire danser et
                vous divertir jusqu'à l'aube.
                <br></br>
                <br></br>Ce qui rend le <b>Ware House</b> unique, c'est son
                Guest Box privé et des DJ de renommée mondiale. Ces artistes de
                premier plan créent des performances spectaculaires, assurant
                une atmosphère festive et dynamique. Que vous veniez pour une
                soirée entre amis ou pour célébrer une occasion spéciale, vous
                ne verrez pas le temps passer. Le <b>Ware House</b> vous promet
                des souvenirs mémorables dans un cadre exclusif et raffiné.
              </p>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-6">
            <div className="resto-img text-end">
              <div className="theme-btn">
                <div className="round-text">
                  <img
                    src={restaurantText}
                    className="img-fluid restaurant-text-img"
                    alt="restaurant-text"
                  />
                  <div className="foholic_letter">F</div>
                </div>
              </div>
              <img src={aboutImg} alt="history img-1" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>

      <div className="food_tasting">
        <div className="row">
          <div className="col-12 col-md-5 col-lg-5">
            <div
              className="season_tast wow fadeInLeft"
              data-wow-duration="1s"
              data-wow-delay="1s"
            >
              <img src={aboutImg2} alt="history img-2" className="img-fluid" />
            </div>
          </div>
          <div className="col-12 col-md-7 col-lg-7">
            <div
              className="tasting_content  wow fadeInRight"
              data-wow-duration="1s"
              data-wow-delay="0.8s"
            >
              <h3 className="foholic_title">
                L'endroit où il faut être. Ouvert chaque vendredi et samedi dès
                22 heures
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div className="resto_foods">
        <h3
          className="foholic_title text-center col-lg-8 m-auto wow fadeIn"
          data-wow-duration="3s"
          data-wow-delay=".4s"
        >
          Des soirées inoubliables avec des DJ de renommée mondiale et un espace
          VIP exclusif
        </h3>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4">
            <div
              className="fo-abo-last wow fadeInLeft"
              data-wow-duration="1s"
              data-wow-delay="0.6s"
            >
              <p className="foholic_subcontent">
                Nous nous distinguons par un service exceptionnel et une
                attention aux détails. Dès votre arrivée, vous serez accueilli
                par une équipe prête à répondre à tous vos besoins. Les
                cocktails, préparés par des barmen talentueux, ajoutent une
                touche à votre soirée. Les jeux de lumière et les effets visuels
                créent une ambiance immersive, transformant chaque soirée en une
                expérience unique. Venez découvrir pourquoi le <b>Ware House</b>{" "}
                est le lieu de prédilection pour ceux qui recherchent
                l'excellence en matière de divertissement.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-8">
            <div className="food-differs">
              <img
                src={aboutImg3}
                alt="history img-3"
                className="img-fluid float-end"
              />
              <img
                src={aboutImg4}
                alt="history img-4"
                className="img-fluid top-img"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarehouseAtNight;
