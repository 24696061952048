import React, { useState, useRef } from 'react'
import emailjs from 'emailjs-com'

const Newsletter = () => {
  const [email, setEmail] = useState('')
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const formRef = useRef()

  const subscribeSuccess = {
    display: showSuccess ? 'block' : 'none',
    color: 'gray',
    marginTop: '10px',
  }

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return re.test(String(email).toLowerCase())
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (!validateEmail(email)) {
      setShowError(true)
      return
    }

    setShowError(false)

    // Send email to your email address
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_NEWSLETTER_SERVICE_ID, // Replace with your EmailJS service ID
        process.env.REACT_APP_EMAILJS_NEWSLETTER_TEMPLATE_ID, // Replace with your EmailJS template ID
        formRef.current,
        process.env.REACT_APP_EMAILJS_USER_ID // Replace with your EmailJS user ID
      )
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text)
        console.log(
          'Subscription successful! A confirmation email has been sent to your address.'
        )

        // Clear form values
        formRef.current.reset()
        setEmail('') // Reset email state if you are using controlled components
        setShowSuccess(true) // Show success message

        // Hide success message after 10 seconds
        setTimeout(() => {
          setShowSuccess(false)
        }, 10000)
      })
      .catch((err) => {
        console.error('Failed to send confirmation email...', err)
        console.log(
          'Subscription successful, but failed to send confirmation email.'
        )
      })
      .catch((err) => {
        console.error('FAILED...', err)
        console.log('Subscription failed. Please try again.')
      })
  }

  return (
    <section
      className='newsletter-part'
      id='news-letter'
      style={{ textAlign: 'start' }}
    >
      <div className='container'>
        <h3 className='d-none'>hidden</h3>
        <div className='row'>
          <div
            className='col-12 col-md-12 col-lg-6 wow fadeInLeft'
            data-wow-duration='1.8s'
            data-wow-delay='.3s'
          >
            <span className='section-heading'>
              ABONNEZ-VOUS A NOTRE NEWSLETTER
            </span>
            <div className='news_sub_detail'>
              Ne manquez aucune de nos recettes et moments spéciaux!
            </div>
          </div>
          <div className='col-12 col-md-12 col-lg-6'>
            <form
              className='newsletter__form wow fadeIn'
              data-wow-duration='2s'
              data-wow-delay='.3s'
              onSubmit={handleSubmit}
              ref={formRef}
            >
              <div className='field-class'>
                <input
                  type='email'
                  id='formExample'
                  name='email'
                  className='form-control-input'
                  placeholder='Votre adresse email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              {showError && (
                <p className='form-check-label'>
                  Merci de saisir une adresse email valide!
                </p>
              )}
              <p className='form-confirm-label' style={subscribeSuccess}>
                Merci de vous être abonné !
              </p>
              <div className='foholic_button'>
                <button className='button-light' type='submit'>
                  Subscribe
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Newsletter
