import galleryImg1 from "../../assets/images/gallery/dj_raph01.jpg";
import galleryImg2 from "../../assets/images/gallery/inauguration01.jpg";
import galleryImg3 from "../../assets/images/gallery/guests01.jpg";
import galleryImg4 from "../../assets/images/gallery/restaurant_empty02.jpg";
import galleryImg5 from "../../assets/images/gallery/guests07.jpg";
import galleryImg6 from "../../assets/images/gallery/guests06.jpg";
import galleryImg7 from "../../assets/images/gallery/guests04.jpg";
import galleryImg8 from "../../assets/images/gallery/lounge_bar.jpg";
import galleryImg9 from "../../assets/images/gallery/night_club02.jpg";
import galleryImg10 from "../../assets/images/gallery/night_club01.jpg";

import chefImg1 from "../../assets/images/best-chef/chef-img1.png";
import chefImg2 from "../../assets/images/best-chef/chef-img2.png";
import chefImg3 from "../../assets/images/best-chef/chef-img3.png";
import chefImg4 from "../../assets/images/best-chef/chef-img4.png";
import chefImg5 from "../../assets/images/best-chef/chef-img5.png";
import chefImg6 from "../../assets/images/best-chef/chef-img6.png";

export const galleryImages = [
  { src: galleryImg1 },
  { src: galleryImg2 },
  { src: galleryImg3 },
  { src: galleryImg4 },
  { src: galleryImg5 },
  { src: galleryImg6 },
  { src: galleryImg7 },
  { src: galleryImg8 },
  { src: galleryImg9 },
  { src: galleryImg10 },
];

export const menuItems = [
  {
    sectionId: "nav-starter-tab",
    name: "Samoussa au Poulet",
    price: "$10",
    description: "Feuilleté croustillant farci de poulet épicé et légumes",
  },
  {
    sectionId: "nav-starter-tab",
    name: "Salade de Fruits",
    price: "$10",
    description:
      "Mélange frais de fruits de saison, servi avec une touche de menthe",
  },
  {
    sectionId: "nav-starter-tab",
    name: "Salade Cesar au Poulet",
    price: "$10",
    description:
      "Laitue croquante, poulet grillé, croûtons, parmesan et sauce César maison",
  },
  {
    sectionId: "nav-starter-tab",
    name: "Salade du Warehouse",
    price: "$15",
    description:
      "Mélange de légumes frais, avocat, tomates cerises, et vinaigrette spéciale",
  },

  {
    sectionId: "nav-starter-tab",
    name: "Crevettes à la spécialité du chef",
    price: "$20",
    description:
      "Crevettes sautées dans une sauce secrète du chef, accompagnées de légumes",
  },
  {
    sectionId: "nav-meals-tab",
    name: "Petit déjeuner",
    price: "$15",
    description:
      "Boisson chaude, viennoiserie, bacon, mix de fruits et dessert et œufs miroirs",
  },
  {
    sectionId: "nav-meals-tab",
    name: "Spécialités Congolaises",
    price: "$15",
    description:
      "Plat traditionnel congolais complet et savoureux composé de fufu, accompagné de poisson (bitoyo, tilapia, capitaine...), de légumes variés (fumbwa, madesu, gombo...) et de différentes viandes (chèvre, porc, boeuf...)",
  },

  {
    sectionId: "nav-meals-tab",
    name: "Poulet au citron et gingembre",
    price: "$25",
    description: "Poulet grillé entier mariné au citron et gingembre",
  },
  {
    sectionId: "nav-meals-tab",
    name: "Porc Ribs",
    price: "$30",
    description:
      "Côte de porc parfaitement taillée, épicée et grillée, accompagnée de patates sautées et d’une salade fraîche",
  },
  {
    sectionId: "nav-meals-tab",
    name: "Planche a Steak Warehouse",
    price: "$35",
    description:
      "Filet de steak, brochettes de poulet, bananes plantains, saucisses et salade",
  },
  {
    sectionId: "nav-meats-tab",
    name: "Poulet Mayo",
    price: "$15",
    description: "Poulet rôti avec mayonnaise, épices et légumes",
  },
  {
    sectionId: "nav-meats-tab",
    name: "Côtelettes de bœuf",
    price: "$20",
    description: "Epicées avec sauce barbecue",
  },

  {
    sectionId: "nav-meats-tab",
    name: "Côtes de porc",
    price: "$20",
    description: "Deux carrés de proc bien épicés, grillés à la sauce barbecue",
  },
  {
    sectionId: "nav-meats-tab",
    name: "T-Bone steak beurre maitre d’hôtel",
    price: "$20",
    description: "T-Bone grillé à la cuisson du client",
  },
  {
    sectionId: "nav-meats-tab",
    name: "Steak, Frites et sauces champignons",
    price: "$20",
    description: "Steak local parfumé aux herbes fines et ragout de champignon",
  },
  {
    sectionId: "nav-desserts-tab",
    name: "Crêpes au chocolat",
    price: "$10",
    description:
      "Crêpes légères et moelleuses, parfaites pour un dessert gourmand ou un petit-déjeuner sucré",
  },
  {
    sectionId: "nav-desserts-tab",
    name: "Tourbillon Fruité",
    price: "$10",
    description: "Cocktail de fruits locaux et exotiques",
  },
  {
    sectionId: "nav-desserts-tab",
    name: "Gaufres aux bananes caramélisées",
    price: "$10",
    description: "Gaufres croustillante garnies de bananes caramélisées",
  },
  {
    sectionId: "nav-desserts-tab",
    name: "Crêpes Jambon-Fromage",
    price: "$10",
    description: "Crêpes garnies de jambon et fromage fondant",
  },
  {
    sectionId: "nav-desserts-tab",
    name: "Crêpes Poulet-Champignons",
    price: "$10",
    description: "Crêpe garnie de poulet tendre et champignons savoureux",
  },
  {
    sectionId: "nav-africans-tab",
    name: "Riz Jolof",
    price: "$10",
    description:
      "Plat de riz épicé, cuisiné avec des tomates, oignons et poivrons",
  },
  {
    sectionId: "nav-africans-tab",
    name: "Poulet Yassa",
    price: "$15",
    description: "Poulet mariné aux oignons, citron et moutarde.",
  },

  {
    sectionId: "nav-africans-tab",
    name: "Bouillon de tripes",
    price: "$15",
    description:
      "Bouillon savoureux à base de tripes, avec tomates, oignons, ail et épices",
  },
  {
    sectionId: "nav-africans-tab",
    name: "Boulettes de Merlan Piquantes",
    price: "$15",
    description: "Boulettes de merlan épicées, avec oignons, ail et piment",
  },
  {
    sectionId: "nav-africans-tab",
    name: "Soupe Egusi",
    price: "$20",
    description:
      "Soupe épicée à base de graines de melon, avec viande et légumes",
  },
  {
    sectionId: "nav-champagnes-tab",
    name: "Luc Belaire Fantome Gold",
    price: "$90",
    description: "",
  },
  {
    sectionId: "nav-champagnes-tab",
    name: "GM. MUMM CORDON ROUGE BRUT",
    price: "$100",
    description: "",
  },
  {
    sectionId: "nav-champagnes-tab",
    name: "Moet & Chandon Nectar",
    price: "$150",
    description: "",
  },

  {
    sectionId: "nav-champagnes-tab",
    name: "Veuve Clickot Rich",
    price: "$160",
    description: "",
  },
  {
    sectionId: "nav-champagnes-tab",
    name: "Ruinart Blanc des Blancs",
    price: "$220",
    description: "",
  },
  {
    sectionId: "nav-champagnes-tab",
    name: "Dom Perignon",
    price: "$500",
    description: "",
  },
  {
    sectionId: "nav-champagnes-tab",
    name: "Armand de Brignac",
    price: "$800",
    description: "",
  },
  {
    sectionId: "nav-spiritueux-tab",
    name: "Martell Blue Swift",
    price: "$100",
    description: "",
  },
  {
    sectionId: "nav-spiritueux-tab",
    name: "Remy Martin",
    price: "$120",
    description: "",
  },
  {
    sectionId: "nav-spiritueux-tab",
    name: "Glenfiddish 18YO",
    price: "$170",
    description: "",
  },
  {
    sectionId: "nav-spiritueux-tab",
    name: "Chivas 21 YO",
    price: "$250",
    description: "",
  },
  {
    sectionId: "nav-spiritueux-tab",
    name: "Hennessy XO",
    price: "$400",
    description: "",
  },
  {
    sectionId: "nav-spiritueux-tab",
    name: "Don Julio 1942",
    price: "$400",
    description: "",
  },
  {
    sectionId: "nav-spiritueux-tab",
    name: "CLASS AZUl Reposado",
    price: "$600",
    description: "",
  },
  {
    sectionId: "nav-soft-tab",
    name: "Ware House Water",
    price: "$2",
    description: "",
  },
  {
    sectionId: "nav-soft-tab",
    name: "Coca Cola 33cl",
    price: "$3",
    description: "",
  },
  {
    sectionId: "nav-soft-tab",
    name: "XXL",
    price: "$4",
    description: "",
  },
  {
    sectionId: "nav-soft-tab",
    name: "Redbull",
    price: "$4",
    description: "",
  },
  {
    sectionId: "nav-soft-tab",
    name: "Jus de Fuit",
    price: "$5",
    description: "",
  },

  {
    sectionId: "nav-soft-tab",
    name: "Cocktail Sans Alcool",
    price: "$10",
    description: "",
  },
  {
    sectionId: "nav-soft-tab",
    name: "Cocktail Alcolisé",
    price: "$15",
    description: "",
  },
  {
    sectionId: "nav-wines-tab",
    name: "Vin Rouge",
    price: "$25",
    description: "",
  },
  {
    sectionId: "nav-wines-tab",
    name: "Cesari Ripasso",
    price: "$25",
    description: "",
  },
  {
    sectionId: "nav-wines-tab",
    name: "Cesari Amarone",
    price: "$25",
    description: "",
  },
  {
    sectionId: "nav-wines-tab",
    name: "Spinelli Montepulciano",
    price: "$25",
    description: "",
  },
  {
    sectionId: "nav-wines-tab",
    name: "Antinori Chianti",
    price: "$25",
    description: "",
  },
  {
    sectionId: "nav-wines-tab",
    name: "Nedergurg",
    price: "$25",
    description: "",
  },

  {
    sectionId: "nav-wines-tab",
    name: "Porto",
    price: "$25",
    description: "",
  },
];

export const menuSections = [
  { id: "nav-starter-tab", label: "Entrées" },
  { id: "nav-meals-tab", label: "Plats" },
  { id: "nav-africans-tab", label: "Africains" },
  { id: "nav-meats-tab", label: "Viandes" },
  { id: "nav-desserts-tab", label: "Desserts" },
  { id: "nav-soft-tab", label: "Soft" },
  { id: "nav-wines-tab", label: "Vins" },
  { id: "nav-champagnes-tab", label: "Champagnes" },
  { id: "nav-spiritueux-tab", label: "Spiritueux" },
];

export const chefsData = [
  {
    img: chefImg1,
    name: "A. Anderson",
    position: "Head Chef",
    details: [
      "Sed luctus massa purus, sed dapibus ex condimentum sed.",
      "Vivamus nulla nibh, dictum nec urna et, tristique elementum mi.",
    ],
    skills: [
      { name: "Behaviour", percentage: 100 },
      { name: "Passionate", percentage: 90 },
      { name: "Motive", percentage: 90 },
    ],
  },
  {
    img: chefImg2,
    name: "B. Brandon",
    position: "Sous Chef",
    details: [
      "Sed luctus massa purus, sed dapibus ex condimentum sed.",
      "Vivamus nulla nibh, dictum nec urna et, tristique elementum mi.",
    ],
    skills: [
      { name: "Behaviour", percentage: 82 },
      { name: "Passionate", percentage: 99 },
      { name: "Motive", percentage: 90 },
    ],
  },
  {
    img: chefImg3,
    name: "C. Charlson",
    position: "Pastry Chef",
    details: [
      "Sed luctus massa purus, sed dapibus ex condimentum sed.",
      "Vivamus nulla nibh, dictum nec urna et, tristique elementum mi.",
    ],
    skills: [
      { name: "Behaviour", percentage: 92 },
      { name: "Passionate", percentage: 60 },
      { name: "Motive", percentage: 80 },
    ],
  },
  {
    img: chefImg4,
    name: "D. Davidson",
    position: "Grill Chef",
    details: [
      "Sed luctus massa purus, sed dapibus ex condimentum sed.Vestibulum eget imperdiet metus, varius facilisis nisl.Integer et sem eros. Pellentesque ullamcorper imperdietlaoreet",
      "Sed luctus massa purus, sed dapibus ex condimentum sed.",
      "Vivamus nulla nibh, dictum nec urna et, tristique elementum mi.",
    ],
    skills: [
      { name: "Behaviour", percentage: 82 },
      { name: "Passionate", percentage: 90 },
      { name: "Motive", percentage: 60 },
    ],
  },
  {
    img: chefImg5,
    name: "E. Edison",
    position: "Sauce Chef",
    details: [
      "Sed luctus massa purus, sed dapibus ex condimentum sed.",
      "Vivamus nulla nibh, dictum nec urna et, tristique elementum mi.",
      "Vivamus nulla nibh, dictum nec urna et, tristique elementummi. Aenean suscipit sem nec neque pharetra, sed pretium leofeugiat. Etiam magna nunc, varius sit amet eleifend sit amet,lobortis nec nulla",
    ],
    skills: [
      { name: "Behaviour", percentage: 82 },
      { name: "Passionate", percentage: 50 },
      { name: "Motive", percentage: 80 },
    ],
  },
  {
    img: chefImg6,
    name: "F. Fordson",
    position: "Fish Chef",
    details: [
      "Vivamus nulla nibh, dictum nec urna et, tristique elementummi. Aenean suscipit sem nec neque pharetra, sed pretium leofeugiat. Etiam magna nunc, varius sit amet eleifend sit amet,lobortis nec nulla",
      "Sed luctus massa purus, sed dapibus ex condimentum sed.",
      "Vivamus nulla nibh, dictum nec urna et, tristique elementum mi.",
    ],
    skills: [
      { name: "Behaviour", percentage: 92 },
      { name: "Passionate", percentage: 86 },
      { name: "Motive", percentage: 98 },
    ],
  },
];
