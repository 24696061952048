import React, { useEffect, useState } from "react";
import "../assets/js/all.js";
import "../assets/js/custom.js";
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";
import mobileLogo from "../assets/images/logo/logo-mobile.svg";
import food from "../assets/images/svg/food.svg";
import foodList from "../assets/images/svg/food-list.svg";
import chef from "../assets/images/svg/chef.svg";
import foodVideo from "../assets/video/food_video.mp4";
import "swiper/swiper-bundle.css";
import "swiper/css/free-mode";
import $ from "jquery";
import Sidebar from "./Sidebar.jsx";
import BlogModal from "./blog/BlogModal.jsx";
import ChefModal from "./chef/ChefModal.jsx";
import SliderSection from "./home/SliderSection.jsx";
import AboutSection from "./home/AboutSection.jsx";
import WarehouseAtNight from "./home/WarehouseAtNight.jsx";
import TeamSection from "./home/TeamSection.jsx";
import MenuSection from "./home/MenuSection.jsx";
import ReservationSection from "./home/ReservationSection.jsx";
import GallerySection from "./home/GallerySection.jsx";
import BlogBox from "./blog/BlogBox.jsx";
import Footer from "./Footer.jsx";
import { Link } from "react-router-dom";
import Newsletter from "./home/Newsletter.jsx";

const Home = () => {
  const [visible, setVisible] = useState(true);
  const [chefDetail, setChefDetail] = useState(true);
  const [blogDetail, setBlogDetail] = useState();

  useEffect(() => {
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 500));
      setVisible(false);
      $(document).ready(function () {
        var target = window.location.hash;
        if (target) {
          $("html, body").animate(
            {
              scrollTop: $(target).offset()?.top,
            },
            "slow"
          );
        }
      });
    };

    fetchData();
  }, []);

  useEffect(() => {
    const wow = new WOW.WOW({ live: false });
    let scrolled = false;
    $(window).on("scroll", () => {
      if (!scrolled) {
        scrolled = true;
        wow.init();
      }
    });
  }, []);

  return (
    <>
      {visible ? (
        <div className="preloader">
          <div className="loader">
            <div className="box">
              <div className="box-inner-1">
                <div className="box-1"></div>
                <div className="box-2"></div>
              </div>
              <div className="box-inner-2">
                <div className="box-3"></div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div id="main-wrap">
          {/* start sidebar header */}
          <Sidebar />
          {/* end sidebar header */}

          {/* start content */}
          <div className="foholic_content">
            {/* mobile menu */}
            <div className="mobile_header">
              <div className="inn-sub-header">
                <div className="logo div-logo-mobile" id="logo">
                  <Link to="/">
                    <img
                      src={mobileLogo}
                      alt="warehousedrc logo"
                      className="img-fluid mobile-logo"
                    />
                  </Link>
                </div>
                <div className="foholic-menubar">
                  <div className="menu-ic">
                    <div className="line-menu line-half first-line"></div>
                    <div className="line-menu"></div>
                    <div className="line-menu line-half last-line"></div>
                  </div>
                </div>
              </div>
            </div>

            {/* start slider section  */}
            <section className="foholic_slider slider-style" id="home">
              <h1 className="d-none">Hidden</h1>
              <SliderSection />
            </section>
            {/* end slider section */}

            {/* start about section */}
            <section
              className="foholic-history"
              id="about"
              style={{ textAlign: "start" }}
            >
              <AboutSection />
            </section>
            {/* end about section */}

            {/* start team section */}
            <section className="foholic_team" id="team">
              <div className="timeline-tag">
                <div className="intro-years text-center">
                  <div className="celebrate_title">SAME</div>

                  {/* <h3 className="fast-counter" data-number="16">
                    GAME
                  </h3> */}
                  <h3>GAME</h3>

                  {/* <CountUp end={100} /> */}
                  <div className="years">different level</div>
                </div>
              </div>
              <div className="container">
                <div className="specialities-top text-center">
                  <span
                    className="wow  section-heading fadeInDown"
                    data-wow-duration="2s"
                    data-wow-delay=".3s"
                  >
                    Rencontrez nos Chefs
                  </span>
                  <h4
                    className="foholic_title dark_head  wow fadeInDown"
                    data-wow-duration="1.2s"
                    data-wow-delay=".2s"
                  >
                    Découvrez l’expertise et la passion de nos chefs cuisiniers.
                  </h4>
                  <p className="col-12 col-lg-8 col-md-10 m-auto">
                    Chaque plat est une œuvre d’art, soigneusement préparée pour
                    offrir une expérience culinaire inoubliable. Notre équipe
                    met un point d’honneur à utiliser des ingrédients frais et
                    de qualité pour créer des saveurs uniques qui raviront vos
                    papilles.
                  </p>

                  <TeamSection setChefDetail={setChefDetail} />
                </div>
                <div className="foholic_button text-center">
                  <Link to="chef" className="button-light">
                    Notre équipe
                  </Link>
                </div>
              </div>
            </section>
            {/* end team section */}

            {/* start Features section */}
            <section className="foholic_features" id="features">
              <div className="container">
                <div
                  className="feature_lite text-center wow fadeIn"
                  data-wow-duration="3s"
                  data-wow-delay=".3s"
                >
                  <span
                    className="wow section-heading fadeInDown"
                    data-wow-duration="2s"
                    data-wow-delay=".3s"
                  >
                    Sentez la différence
                  </span>
                  <h2
                    className="dark_head wow fadeInDown"
                    data-wow-duration="1.2s"
                    data-wow-delay=".2s"
                  >
                    Pourquoi nous choisir?
                  </h2>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-4">
                    <div className="dish-ic-box text-center">
                      <img src={food} alt="food-img" className="img-fluid" />
                      <h3 className="m-0">Service Exceptionnel</h3>
                      <p className="m-0">
                        Nous nous assurons que chaque client reçoive un service
                        personnalisé et attentionné, rendant chaque repas
                        mémorable.
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-lg-4">
                    <div className="dish-ic-box text-center">
                      <img
                        src={foodList}
                        alt="food-list-img"
                        className="img-fluid"
                      />
                      <h3 className="m-0">Qualité des Ingrédients</h3>
                      <p className="m-0">
                        Nous utilisons uniquement des ingrédients frais et de
                        haute qualité pour garantir des plats savoureux à
                        chacune de vos visite.
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-lg-4">
                    <div className="dish-ic-box text-center">
                      <img src={chef} alt="chef-img" className="img-fluid" />
                      <h3 className="m-0">Expérience Unique</h3>
                      <h4 className="d-none">hidden</h4>
                      <p className="m-0">
                        Profitez d’une atmosphère chaleureuse et élégante,
                        parfaite pour des dîners en famille, des sorties entre
                        amis ou des soirées romantiques.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* end Features section */}

            {/* start video section */}
            <section className="food_video" id="video">
              <div className="parallax-overlay"></div>
              <div className="container text-center">
                <h5
                  className="video_title wow fadeInDown"
                  data-wow-duration="2s"
                  data-wow-delay=".3s"
                >
                  Nos plats s’inspirent des riches traditions culinaires
                  congolaises, africaines et européennes, créant des saveurs
                  authentiques qui célèbrent notre patrimoine culturel.
                </h5>
                <div className="parallax-content">
                  {/* button start */}
                  <div className="more-wraper">
                    <a
                      href="javascript;"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <div className="watch-button watch-button-circle"></div>
                      <div className="watch_video_button">
                        <span>Voir Plus</span>
                      </div>
                    </a>
                  </div>
                  {/* button end */}
                </div>
              </div>
            </section>
            {/* end video section */}

            {/* start menu section */}
            <section
              className="foholic_menu"
              id="menu"
              style={{ textAlign: "start" }}
            >
              <MenuSection />
            </section>
            {/* end menu section */}
            {/* start night club section */}
            <section
              className="foholic-history"
              id="nightclub"
              style={{ textAlign: "start" }}
            >
              <WarehouseAtNight />
            </section>
            {/* end about section */}

            {/* start reservation section */}
            <section
              className="foholic_reservation"
              id="reservation"
              style={{ textAlign: "start" }}
            >
              <div className="parallax-overlay"></div>
              <h6 className="d-none">hidden</h6>
              <ReservationSection />
            </section>
            {/* end reservation section  */}

            {/* start gallery section */}
            <section className="foholic_gallery" id="gallery">
              <h2 className="d-none">hidden</h2>
              <div className="container">
                <div className="divide_gallery text-center">
                  <span
                    className="wow section-heading fadeInDown"
                    data-wow-duration="2s"
                    data-wow-delay=".3s"
                  >
                    Découvrez Nos Lieux
                  </span>
                  <div
                    className="foholic_title dark_head wow fadeInDown"
                    data-wow-duration="1.2s"
                    data-wow-delay=".2s"
                  >
                    Le Ware House en images
                  </div>
                  <p className="col-12 col-lg-8 col-md-10 m-auto">
                    Plongez dans l’univers unique de notre complexe à travers
                    notre galerie photos. Découvrez l’ambiance chaleureuse de
                    notre restaurant, l’élégance de notre lounge, et l’énergie
                    vibrante de notre nightclub. Laissez-vous inspirer par nos
                    espaces soigneusement conçus pour offrir une expérience
                    inoubliable à chaque visite
                  </p>
                </div>
                <GallerySection />
              </div>
            </section>
            {/* end gallery section */}

            {/* start blog section */}
            <section className="blog_section" id="blog">
              <div className="our_blog">
                <div className="container">
                  <div className="text-center blog_detail">
                    <span
                      className="wow section-heading fadeInDown"
                      data-wow-duration="2s"
                      data-wow-delay=".3s"
                    >
                      Restez à jour
                    </span>
                    <h2
                      className="dark_head wow fadeInDown"
                      data-wow-duration="1.2s"
                      data-wow-delay=".2s"
                    >
                      Actu Ware House
                    </h2>
                    <p className="col-12 col-lg-8 col-md-10 m-auto">
                      Restez informé des dernières nouvelles et événements du
                      <b> Ware House</b>. Découvrez nos services exclusifs, les
                      événements à venir et les moments forts qui font de notre
                      complexe un lieu incontournable.
                    </p>
                  </div>
                </div>
              </div>

              <div className="blog_boxes">
                <BlogBox setBlogDetail={setBlogDetail} />
              </div>
            </section>
            {/* end blog section */}

            <Newsletter />
            {/* start location section */}
            <section className="foholic-location text-center" id="contact">
              <div className="container">
                <span
                  className="wow fadeInDown section-heading"
                  data-wow-duration="2s"
                  data-wow-delay=".3s"
                >
                  Où nous trouver ?
                </span>
                <h3
                  className="location-title dark_head wow fadeInDown"
                  data-wow-duration="1.2s"
                  data-wow-delay=".2s"
                >
                  Notre Adresse
                </h3>
                <div
                  className="foholic_map wow fadeIn"
                  data-wow-duration="3s"
                  data-wow-delay=".4s"
                >
                  <iframe
                    title="map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15630.693898478317!2d27.461760998691773!3d-11.646600666959321!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19723f3fd8ec0d75%3A0x2f717ed401916b12!2sWare%20House%20DRC!5e0!3m2!1sen!2scd!4v1724096995445!5m2!1sen!2scd"
                    width="1290"
                    height="482"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </section>
            {/* end location section */}

            {/* start footer section */}
            <Footer />
            {/* end footer section */}
          </div>
          {/* end content */}

          {/* video section Modal */}
          <div
            className="modal fade video_modal"
            id="exampleModal"
            tabIndex="-1"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <video
                    width="727"
                    height="410"
                    controls
                    playsInline
                    autoPlay
                    muted
                    loop
                  >
                    <source src={foodVideo} type="video/mp4" />
                  </video>
                </div>
              </div>
            </div>
          </div>
          {/* end Modal */}

          {/* blog section Modal */}
          <div
            className="modal-fullscreen modal fade blog-modal"
            id="staticBackdrop"
            tabIndex="-2"
            aria-hidden="true"
            style={{ textAlign: "start" }}
          >
            <BlogModal blogDetail={blogDetail} />
          </div>

          {/* team modal */}
          <div
            className="modal fade team_blog reveal"
            id="staticBackdropteam"
            tabIndex="-1"
            aria-hidden="true"
            style={{ textAlign: "start" }}
          >
            <ChefModal chefDetail={chefDetail} />
          </div>

          {/* Scroll Bottom to Top */}
          <span className="scroll-top" id="scroll-to-top" data-scroll="up">
            <i className="fa-solid fa-angle-up"></i>
          </span>
        </div>
      )}
    </>
  );
};

export default Home;
