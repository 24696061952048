import React from "react";
import message from "../assets/images/footer/message.png";
import backgroundShape1 from "../assets/images/svg/background-shape1.svg";
import backgroundShape2 from "../assets/images/svg/background-shape2.svg";
import location from "../assets/images/footer/gps.png";
import callInfo from "../assets/images/footer/phone-call.png";

const Footer = () => {
  return (
    <footer className="footer-area text-center" id="footer">
      <div className="parallax-overlay"></div>
      <div className="background-shape1">
        <img src={backgroundShape1} alt="background-shape1 img" />
      </div>
      <div className="background-shape2">
        <img src={backgroundShape2} alt="background-shape2 img" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-3">
            <h5>The Ware House</h5>
            <p>
              Restaurant - Lounge Bar - Night Club
              <br />
              Same Game, Different Level
            </p>
            <div className="location-info">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://maps.app.goo.gl/XtingEUg1a9GwZQd8"
              >
                <img
                  src={location}
                  alt="location-ic"
                  className="img-fluid"
                  width={48}
                  height={48}
                />{" "}
                <p>
                  975, Avenue Lufira, Lubumbashi,
                  <br /> D.R. Congo
                </p>
              </a>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-3">
            <h5>Contact</h5>
            <div className="location-info">
              <a href="tel:+243858876575">
                {" "}
                <img
                  src={callInfo}
                  alt="call-info-ic"
                  className="img-fluid icon"
                  width={40}
                  height={40}
                />{" "}
                <p>+243 858 876 575</p>
              </a>
            </div>
            <div className="location-info">
              <a href="mailto:info@warehousedrc.com">
                <img
                  src={message}
                  alt="message-ic"
                  className="img-fluid icon"
                  width={40}
                  height={40}
                />{" "}
                <p>info@warehousedrc.com</p>
              </a>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <h5>Business Hours</h5>
            <h6 className="section-heading">Restaurant & Lounge Bar</h6>

            <p>
              Tue - Sun: 07:30 AM - 11:59 PM <br />
              Monday: Closed
            </p>

            <h6 className="section-heading">Night Club</h6>
            <p>
              Fri & Sat: Doors Opens at 10:00 PM
              <br />
              Sunday - Thursday: Closed
            </p>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <h5>Follow Us</h5>
            <div className="foholic-social-ic">
              <a
                href="https://www.facebook.com/U.E.K.Officiel?mibextid=LQQJ4d"
                className="social-ic"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-facebook-f"></i>
              </a>
              <a
                href="https://www.tiktok.com/@warehousedrc?_t=8p0afHt7fGg&_r=1"
                className="social-ic"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-tiktok"></i>
              </a>

              <a
                href="https://www.instagram.com/warehousedrc?igsh=MTl0cTc1M3V1OXB0Mg%3D%3D&utm_source=qr"
                className="social-ic"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-instagram"></i>
              </a>
              <a
                href="https://www.youtube.com/@WareHouseDRC"
                className="social-ic"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-youtube"></i>
              </a>
              <a
                href="https://www.google.com/maps/place/Ware+House+Night+Club+Lubumbashi/@-11.6532521,27.4674559,15z/data=!4m20!1m13!4m12!1m4!2m2!1d27.4673842!2d-11.660213!4e1!1m6!1m2!1s0x19723f3fd8ec0d75:0x2f717ed401916b12!2sware+house+drc+lubumbashi!2m2!1d27.4777556!2d-11.6532521!3m5!1s0x19723f3fd8ec0d75:0x2f717ed401916b12!8m2!3d-11.6532521!4d27.4777556!16s%2Fg%2F11w7prz3fb?entry=ttu&g_ep=EgoyMDI0MDgyMy4wIKXMDSoASAFQAw%3D%3D"
                className="social-ic"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-google"></i>
              </a>
            </div>
          </div>
        </div>
        <div
          className="sub-footer text-center wow fadeIn"
          data-wow-duration="3s"
          data-wow-delay=".4s"
        >
          <span>
            © Copyright {new Date().getFullYear()} The Warehouse DRC. Tous
            droits réservés
            <br /> Construit par{" "}
            <a
              href="https://katshi.dev"
              target="_blank"
              rel="noopener noreferrer"
            >
              Katshi.dev
            </a>{" "}
            &{" "}
            <a
              href="https://afropalette.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Afro Palette
            </a>
            <br /> Crédits photos :{" "}
            <a
              href="https://shabaindustrie.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Shaba Industry
            </a>
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
