import React from "react";

const ChefModal = ({ chefDetail }) => {
  return (
    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div className="modal-content">
        <div className="modal-body">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-6">
              <div className="team_popup_box text-center">
                <img
                  src={chefDetail?.img}
                  alt={chefDetail?.name}
                  className="img-fluid"
                />
                <h5 className="m-0">{chefDetail?.name}</h5>
                <span>{chefDetail?.position}</span>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <div className="team_detail">
                {chefDetail?.details?.map((detail, index) => (
                  <p key={index}>{detail}</p>
                ))}
                <div className="skill_bar">
                  {chefDetail?.skills?.map((skill, index) => (
                    <div className="progresbar-counter" key={index}>
                      <p className="progress-title text-start">{skill?.name}</p>
                      <div
                        className="progress-value"
                        style={{ left: `${skill?.percentage}%` }}
                      >
                        <span
                          className="count num-counter"
                          data-number={skill.percentage}
                        >
                          {skill?.percentage}
                        </span>
                        %
                      </div>
                      <div className="skil-progressbar">
                        <span style={{ width: `${skill?.percentage}%` }}></span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="more-wraper" data-bs-dismiss="modal">
              <div className="watch-button watch-button-circle"></div>
              <div className="watch_video_button">
                <span>Close</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChefModal;
